import { imageAltTextIncludesSku } from '#root/shared/utils/product-utils';

function mapVariantsData(variants) {
  if (variants?.edges?.length) {
    return variants.edges.map((variantEdge) => {
      const variant = variantEdge?.node;
      return {
        id: variant?.entityId,
        sku: variant?.sku,
        prices: variant?.prices,
        inventory: variant?.inventory,
        is_purchasable: variant?.isPurchasable,
        lowest30DaysPrice: variant?.lowest30DaysPrice,
        option_values: variant?.options?.edges?.map((optionEdge) => {
          const option = optionEdge?.node;
          const optionValues = option?.values?.edges?.map((optionValueEdge) => {
            const optionValue = optionValueEdge?.node;
            return {
              id: optionValue?.entityId,
              display_name: option?.displayName,
              label: optionValue?.label,
              option_id: option?.entityId,
            };
          });

          return optionValues[0];
        }),
        campaign: variant?.campaign,
        bundle: variant?.bundle,
        units: variant?.units,
      };
    });
  }

  return [];
}

const mapOptionsData = (variants) => {
  const options = [];

  variants?.edges?.forEach(({ node: variant }) => {
    const { basePrice, salePrice } = variant.prices;

    return variant?.options?.edges?.forEach(({ node: optionVariant }) =>
      optionVariant.values.edges?.forEach(({ node: optionValue }) => {
        const optionIndex = options.findIndex(
          (op) => op.id === optionVariant.entityId
        );

        if (optionIndex < 0) {
          // Does not already contain this option type
          options.push({
            id: optionVariant.entityId,
            display_name: optionVariant.displayName,
            values: [
              {
                id: optionValue.entityId,
                label: optionValue.label,
                base_price: basePrice?.value,
                sale_price: salePrice?.value,
              },
            ],
          });
        } else if (
          options?.[optionIndex]?.values?.findIndex(
            (ov) => ov.id === optionValue.entityId
          ) < 0
        ) {
          // Does not already contain this option type value
          options[optionIndex].values.push({
            id: optionValue.entityId,
            label: optionValue.label,
            base_price: basePrice?.value,
            sale_price: salePrice?.value,
          });
        }
      })
    );
  });

  return options;
};

function mapImagesData(product) {
  if (!product) {
    return [];
  }

  const { variants, images, name } = product;

  return (
    images?.edges?.map((imageEdge) => {
      const imageSkus = imageEdge.node.altText;

      // Find variants matching image alt text (SKU, ex. '31-0214, 31-0213')
      const matchedVariants = variants?.edges?.length
        ? variants.edges.filter((v) =>
            imageAltTextIncludesSku(imageSkus, v?.node?.sku)
          )
        : [];

      // Concatenate option values (ex. 'blue small, blue medium') to alt text
      const alt = matchedVariants?.length
        ? matchedVariants.reduce((acc, variant, i) => {
            const label = variant?.node?.options?.edges
              .reduce(
                (labelAcc, v) => [
                  ...labelAcc,
                  ...(v.node?.values?.edges?.map((n) => n?.node?.label || '') ||
                    []),
                ],
                []
              )
              .join(' ');

            return i === 0 ? `${acc} - ${label}` : `${acc}, ${label}`;
          }, name)
        : name;

      return {
        id: imageSkus,
        src: imageEdge.node.urlOriginal,
        alt,
      };
    }) || []
  );
}

function mapProductsData(products) {
  if (products?.edges?.length) {
    return products.edges.map((edge) => {
      const variants = mapVariantsData(edge.node.variants);
      const options = mapOptionsData(edge.node.variants);

      const categoryIds = edge?.node?.categoryIds
        ? edge?.node?.categoryIds
        : edge?.node?.categories?.edges?.map(
            (categoryEdge) => categoryEdge?.node?.entityId
          ) || [];

      return {
        ...edge,
        node: {
          ...edge.node,
          categoryIds,
          variants,
          options,
        },
      };
    });
  }

  return [];
}

export { mapProductsData, mapVariantsData, mapOptionsData, mapImagesData };
