// Find variant sku matching image alt text (SKU, ex. '31-0214, 31-0213')
const imageAltTextIncludesSku = (imageAltText: string, variantSku: string) => {
  const matchingSkuIndex = imageAltText
    .split(',')
    .findIndex((imageSku: string) => variantSku === imageSku.trim());

  return matchingSkuIndex > -1;
};

export { imageAltTextIncludesSku };
